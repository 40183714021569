/**
 * Header Style
 */
.logo {
  display: block;
  width: 142px;
  height: 50px;
}
.social-link {
  position: absolute;
  list-style: none;
  top: 40px;
  right: 0;
  margin: 0;
  padding: 0;
  @include clearfix;
  &-item {
    float: left;
    margin-top: 9px;
    margin-left: 20px;
    width: 32px;
    height: 32px;
    &:first-child {
      margin-left: 0;
    }
    > a {
      display: block;
      height: 100%;
      background-image: url(../img/shared/icon_sns.png);
      background-repeat: no-repeat;
      @include image-replace;
    }
  }
  &-instagram > a {
    background-position: 0 0;
    &:hover {
      background-position: 0 -32px;
    }
  }
  &-twitter > a {
    background-position: -32px 0;
    &:hover {
      background-position: -32px -32px;
    }
  }
  &-facebook > a {
    background-position: -64px 0;
    &:hover {
      background-position: -64px -32px;
    }
  }
}
