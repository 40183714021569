/**
 * Title Style
 */
.page-title {
  margin: 0;
  text-align: center;
  &-policy {
    height: 88px;
  }
}
.page-description {
  margin: 35px 0 0 0;
  color: #999999;
  letter-spacing: .1em;
  text-align: center;
}
.primary-title {
  margin: 0;
  padding-bottom: 20px;
  background-repeat: no-repeat;
  background-position: left bottom;
  color: #fff;
  font-size: 2.4rem;
  font-weight: normal;
  letter-spacing: .25em;
  text-align: center;
  &-col8 {
    background-image: url(../img/shared/brd_col8_white.png);
  }
  &-col4 {
    background-image: url(../img/shared/brd_col4_white.png);
  }
  &-number {
    display: block;
  }
  > a {
    text-decoration: none;
    color: #fff;
    &:hover {
      opacity: .7;
    }
  }
}
.secondary-title {
  position: relative;
  margin: 0;
  padding: 6px 20px;
  border: 1px solid #999;
  color: #fff;
  font-weight: normal;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 0;
    height: 0;
    border-top: 20px solid transparent;
    border-right: 0 solid transparent;
    border-bottom: 20px solid transparent;
    border-left: 10px solid #999;
  }
}
