/**
 * Nav Style
 */
.global-nav {
  list-style: none;
  padding: 0;
  @include container;
  @include clearfix;
  &-item {
    float: left;
    margin: 0;
    padding: 0;
    > a {
      display: block;
      height: 160px;
      background-image: url(../img/shared/gnav.png);
      background-repeat: no-repeat;
      @include image-replace;
    }
    @for $i from 1 through 6 {
      &-#{$i} {
        $x: 183px * ($i - 1) * -1;
        > a {
          background-position: $x 0;
          width: 183px;
          &:hover,
          &.is-current {
            background-position: $x -160px;
          }
        }
      }
    }
    &-6 {
      > a {
        width: 185px;
      }
    }
  }
}
.local-nav {
  display: flex;
  justify-content: center;
  list-style: none;
  margin: 50px 0 0;
  padding: 0;
  > li {
    display: block;
    margin: 0;
    padding: 0;
    line-height: 1.1;
    &:not(:first-child) {
      border-left: 1px solid #fff;
    }
    > a {
      position: relative;
      display: block;
      padding: 0 1em;
      font-size: 1.6rem;
      letter-spacing: 4px;
      color: #fff;
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
