@use 'sass:math';
/**
 * Footer Style
 */
.footer {
  &-link {
    margin: 0;
    padding: 0;
    &-item {
      display: inline-block;
      margin-left: 50px;
      line-height: math.div(30, 14);
      &:first-child {
        margin-left: 0;
      }
      > a {
        text-decoration: none;
      }
    }
  }
}
.copyright {
  position: absolute;
  top: 38px;
  right: 0;
  margin: 0;
  line-height: 1;
}
