@use 'sass:math';
/**
 * Text Style
 */
.text {
  margin: 1em 0 0 0;
  color: #fff;
  &:first-child {
    margin-top: 0;
  }
}
.primary-text {
  margin: 1em 0 0 0;
  color: #fff;
  font-size: 1.8rem;
  line-height: math.div(30, 18);
  &:first-child {
    margin-top: 0;
  }
}
.text-align-center {
  text-align: center;
}
