/**
 * Works Style
 */

.pickup {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px dotted #adadad;
  &-first {
    padding-top: 0;
    border-top: none;
  }
  &-header {
    margin: 0;
    font-weight: normal;
  }
  &-body {
    margin-top: 30px;
  }
  &-detail {
    margin: 30px 0 0 0;
    width: 260px;
  }
}
.building {
  &-container {
    margin-top: 40px;
    border-top: 1px dotted #adadad;
  }
  &-list {
    list-style: none;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: -20px;
    padding: 0;
    @include clearfix;
  }
  &-item {
    float: left;
    margin-top: 40px;
    margin-left: 20px;
    width: 260px;
    height: 250px;
  }
  &-text {
    margin-top: 10px;
    color: #999;
    font-size: 1.2rem;
  }
  &-galley {
    display: block;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      width: 44px;
      height: 44px;
      background-image: url(../img/works/icon_pic02.png);
      background-repeat: no-repeat;
      background-position: 0 0;
    }
    &:hover {
      opacity: .7;
    }
  }
  &-category {
    display: inline-block;
    margin-right: 10px;
    padding: 0 3px;
    border: 1px solid #999;
    vertical-align: middle;
    .pickup-header & {
      border-color: #808080;
      color: #ccc;
      font-size: 1.6rem;
    }
  }
  &-name {
    display: inline-block;
    vertical-align: middle;
    .pickup-header & {
      color: #fff;
      font-size: 2.4rem;
      line-height: 1;
    }
  }
  &-name-block {
    display: block;
    margin-top: 10px;
  }
}
.slides-container {
  padding: 100px 140px;
  width: 1100px;
}
.slides {
  position: relative;
  width: 820px;
  height: 547px;
  &-button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 27px;
    height: 85px;
    background-image: url(../img/colorbox/btn_arrow.png);
    background-repeat: no-repeat;
    cursor: pointer;
  }
  &-button-prev {
    left: -47px;
    background-position: 0 0;
    &:hover {
      background-position: 0 -85px;
    }
  }
  &-button-next {
    right: -47px;
    background-position: -27px 0;
    &:hover {
      background-position: -27px -85px;
    }
  }
}
.slides-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 820px;
  height: 547px;
  overflow: hidden;
  > li {
    float: left;
    width: 820px;
    height: 547px;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
  }
  img {
    width: auto;
    max-height: 547px;
  }
  .is-loading {
    background-image: url(../img/colorbox/loading.gif);
    > img {
      opacity: 0;
    }
  }
}
.pager {
  margin: 0 0 0 -20px;
  width: 840px;
  @include clearfix;
  > a {
    display: block;
    float: left;
    margin-top: 20px;
    margin-left: 20px;
    width: 120px;
    height: 80px;
    background-position: center center;
    background-repeat: no-repeat;
    text-align: center;
    > img {
      transition: opacity 0.4s;
    }
  }
  .selected {
    opacity: .7;
  }
  img {
    width: auto;
    max-height: 100%;
  }
  .is-loading {
    background-color: #f0f0f0;
    background-image: url(../img/colorbox/loading.gif);
    > img {
      opacity: 0;
    }
  }
}
