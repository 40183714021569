/**
 * Grid Style
 */
.row {
  width: 1100px;
}
@include rows(1100px,8,20px,'row');
.row4 {
  width: 540px;
}
@include rows(540px,4,20px,'row4');
