@use 'sass:math';
/**
 * Process Style
 */

.process {
  margin-top: 60px;
  &-first {
    margin-top: 40px;
  }
  &-text {
    margin: 2em 0 0 0;
    color: #fff;
    line-height: math.div(30, 14);
    &:first-child {
      margin-top: 0;
    }
  }
  &-contact {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    > .tel {
      color: #fff;
      font-size: 2.4rem;
      font-weight: normal;
      cursor: default;
      text-decoration: none;
    }
  }
  &-after-container {
    position: relative;
  }
  &-after {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
