@use 'sass:math';
/**
 * Information Style
 */
.facebook-likebox {
  width: 540px;
  height: 300px;
}
.contact {
  @include clearfix;
  &-title {
    margin: 0;
  }
  &-tel {
    float: left;
    margin: 20px 35px 0 0;
    width: 299px;
  }
  &-text {
    float: left;
    margin: 16px 0 0 0;
    line-height: math.div(22, 14);
  }
  &-button {
    clear: both;
    display: inline-block;
    background-color: #333;
    margin-top: 20px;
    padding: 23px 40px;
    width: inherit;
    color: #fff;
    font-size: 1.4rem;
    text-decoration: none;
    &:hover {
      color: #fff;
      opacity: .7;
    }
  }
}
.news {
  margin-top: 50px;
  &-title {
    margin: 0;
  }
  &-list {
    list-style: none;
    margin: 12px 0 0 0;
    padding: 0;
    &-item {
      line-height: math.div(30, 14);
    }
    &-date {
      margin-right: 26px;
    }
    &-text {

    }
  }
}
.insta-slide-container {
  position: relative;
  margin: 0 auto;
  > a {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }
}
.insta-slide {
  position: relative;
  padding-bottom: 8.3%;
  height: 0;
  width: 100%;
  overflow: hidden;
  iframe {
    border: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
