/**
 * Layout Style
 */
.l-header {
  height: 90px;
  &-inner {
    position: relative;
    padding-top: 40px;
    height: 90px;
    @include container;
  }
}
.l-message {
  &-inner {
    position: relative;
    @include container;
  }
}
.l-global-nav {
  height: 160px;
  background-color: #f0f0f0;
}
.l-main {
  background-color: #333333;
  &-inner {
    padding: 100px 0;
    @include container;
    &-policy {
      padding-top: 0;
    }
  }
  &-policy {
    background-color: #fff;
  }
}
.l-contents {
  margin: 80px 0 0 0;
}
.l-information {
  &-inner {
    padding: 50px 0;
    @include container;
  }
}
.l-footer {
  background-color: #f0f0f0;
  &-inner {
    position: relative;
    padding: 30px 0;
    @include container;
  }
}
