@use 'sass:math';
/**
 * Base Style
 */
*,
*:after,
*:before {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  min-width: 1200px;
  color: $text-base-color;
  font-size: 1.4rem;
  font-family: "Avenir Next", Verdana, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "游ゴシック", "Yu Gothic", "メイリオ", Meiryo, sans-serif;
  line-height: math.div(24, 14);//1.75
}
a {
  color: $link-base-color;
  text-decoration: underline;
  &:hover {
    color: $link-base-color;
    text-decoration: none;
  }
}
img {
  max-width: 100%;
  vertical-align: bottom;;
}
