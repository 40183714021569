/**
 * Button Style
 */

.button {
  position: relative;
  display: block;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  text-decoration: none;
  text-align: center;
  &-arrow:before {
    content: "";
    position: absolute;
    left: 20px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    border-top: 2px solid #333;
    border-right: 2px solid #333;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
  }
  &:hover {
    opacity: .7;
  }
  &-label {
    display: inline-block;
    color: #333;
    font-size: 1.6rem;
    vertical-align: middle;
  }
  &-galley {
    background-image: url(../img/works/icon_pic01.png);
    background-position: 10px 50%;
    background-repeat: no-repeat;
  }
}
.button-recruit {
  clear: both;
  display: inline-block;
  background-color: #F0F0F0;
  margin: 55px auto 0;
  padding: 20px 40px;
  min-width: 223px;
  font-size: 1.8rem;
  text-decoration: none;
  text-align: center;
  &:hover {
    opacity: .7;
  }
}
