/**
 * Message Style
 */
.message {
  padding: 70px 0;
  min-height: 377px;
  background-repeat: no-repeat;
  background-position: 100% 70px;
  &-title {
    margin: 0;
    height: 117px;
  }
  &-text {
    margin: 30px 0 0 0;
    font-size: 1.4rem;
  }
  &01 {
    background-image: url(../img/shared/img_message01.jpg);
  }
  &02 {
    background-image: url(../img/shared/img_message02.jpg);
  }
  &03 {
    background-image: url(../img/shared/img_message03.jpg);
  }
  &04 {
    background-image: url(../img/shared/img_message04.jpg);
  }
}
