@use 'sass:math';
@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
@mixin image-replace {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
@mixin rows($rowwidth:1100px, $col:8, $gutter: 20px, $class:'row') {
  $grid: math.div(($rowwidth - (($col - 1) * $gutter)) , $col);
  .#{$class} > [class*='col-'] {
    margin-left: $gutter;
    float: left;
  }
  .#{$class} > [class*='col-']:first-child {
    margin-left: 0;
  }
  .#{$class}:after {
    content: "";
    display: table;
    clear: both;
  }
  @for $i from 1 through $col
  {
    $num: $col - $i + 1;
    $width: ($grid * $num) + ($gutter * ($num - 1));
    .#{$class} > .col-#{$num}
    {
      width: $width;
    }
    @if $num < $col
    {
      .#{$class} > [class*='col-'].offset-by-col-#{$num}
      {
        margin-left: $width + $gutter;
      }
    }
  }
}
@mixin container {
  margin: 0 auto;
  width: $container-width;
}
