/**
 * Table Style
 */
.table {
  th {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #fff;
    font-weight: normal;
    text-align: left;
    vertical-align: top;
    letter-spacing: .1em;
  }
  td {
    padding-top: 12px;
    padding-bottom: 12px;
    color: #fff;
    letter-spacing: .1em;
  }
  .cell-1 {
    width: 140px;
    padding-right: 20px;
  }
  tr:first-child th,
  tr:first-child td {
    padding-top: 0;
  }
}
