/**
 * Buisiness Style
 */
.business {

  &-image {
    margin: 0;
    &-caption {
      color: #fff;
    }
  }
  &-item {
    margin-top: 40px;
    &-body {
      margin-top: 20px;
    }
  }
  &-flow-image {
    margin-top: 20px;
  }
}
