/**
 * Modal Style
 */
.modal {
  $padLR: ($container-width - $modalcontainer-width)*.5;
  padding: 80px $padLR;
  width: $container-width;
  background-color: #fff;
  &-header {
    padding-bottom: 70px;
    background: url(../img/shared/brd_col6_black.png) no-repeat left bottom;
  }
  &-body {
    padding-top: 30px;
  }
}
