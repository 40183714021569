/**
 * State Style
 */
.is-hidden {
  display: none;
}
.js-parallax {
  position: relative;
  visibility: hidden;
}
