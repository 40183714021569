/**
 * Icon Style
 */

.icon {
  display: inline-block;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: left top;
  vertical-align: middle;
  &-pdf {
    width: 26px;
    height: 30px;
    background-image: url(../img/shared/icon_pdf.png);
  }
}
