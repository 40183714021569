/**
 * Block Style
 */

.container-odd {
  margin-right: 10px;
}
.container-even {
  margin-left: 10px;
}
.section-body {
  margin: 30px 0 0 0;
}
.company-data {
  padding: 40px 0 30px 0;
  background-image: url(../img/shared/brd_col4_white.png), url(../img/shared/brd_col4_white.png);
  background-repeat: no-repeat, no-repeat;
  background-position: 0 0, left bottom;
}
.sdgs-image {
  margin-top: 20px;
}
.map {
  width: 530px;
  height: 627px;
  &-link {
    display: block;
    margin-top: 5px;
    text-align: right;
    > a {
      color: #fff;
    }
  }
}
