@use 'sass:math';
/**
 * List Style
 */
.list {
  list-style: disc;
  margin: 0;
  padding-left: 20px;
}
.list-textorder {
  list-style: none;
  margin: 0;
  padding-left: 0;
  > li {
    margin-top: 20px;
    padding-left: 2em;
    text-indent: -2em;
  }
}
.list-textorder-lv2 {
  list-style: none;
  margin: 0;
  padding-left: 0;
  > li {
    padding-left: 1.5em;
    text-indent: -1.5em;
  }
}
.olist {
  list-style: none;
  margin: 0;
  padding-left: 0;
  &-item {
    position: relative;
    margin: 20px 0 0 0;
    padding-left: 35px;
    color: #fff;
    font-size: 1.8rem;
    line-height: math.div(28, 18);
    &:first-child {
      margin-top: 0;
    }
    $itemnums: '01','02','03','04','05','06','07','08','09','10';
    @each $value in $itemnums
    {
      $key: index($itemnums, $value);
      &:nth-child(#{$key}):before {
        content: '#{$value}';
        position: absolute;
        top: 2px;
        left: 0;
        padding: 5px 3px;
        border-radius: 50%;
        background-color: #fff;
        color: #555555;
        font-size: 1.3rem;
        line-height: 1;
      }
    }
  }
}
