/**
 * Policy Style
 */
.policy {
  margin-top: 20px;
  &-title {
    margin: 0;
    font-size: 1.4rem;
    font-weight: normal;
  }
  &-text {
    margin: 0;
  }
  &-description {
    display: table-row;
    margin: 0;
    > dt {
      display: table-cell;
      margin: 0;
      width: 120px;
    }
    > dd {
      display: table-cell;
      margin: 0;
    }
  }
}
